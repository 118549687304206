import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class CommonListValuesService extends BaseService {
    public constructor(public httpClient: HttpClient) {
        super();
    }

    public listValues(value: string, sort: string = '-code'): Observable<any> {
        const params = { 'filter[code]': value, 'sort': sort };

        return this.httpClient.get(`${this.apiUrl}/common-list-values`, { params: params })
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public listValuesMultiple(values: string[]): Observable<any> {
        const params = values.reduce((acc, val, i) => {
            acc[`filter[${i}]`] = val;
            return acc;
        }, {});

        return this.httpClient.get(`${this.apiUrl}/common-list-values/multiple`, { params: params })
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }
}
