export const environment = {
    production: false,
    apiUrl:     'https://calmag-dev.think360hub.net/api/v1',
    //apiUrl:     'https://staging.api1.blend.theonecrm.co.uk/api/v1',
    webUrl:     'https://calmag.port-ability.in/#',
    user:       {
        email:    'd.tesfagabr@theonepoint.co.uk',
        password: 'password',
    },
    pusher:     {
        app_id:  '1376652',
        key:     'dc96561c584eca6c55dc',
        secret:  '9bcb7dbfb8e541684ea4',
        cluster: 'eu',
    },
};
