import {Component, OnInit}                  from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService}                        from '../../services/auth.service';
import {ActivatedRoute, Router}             from '@angular/router';
import {HttpErrorResponse}                  from '@angular/common/http';
import {environment}                        from '../../../../../environments/environment';
import {MatSnackBar}                        from '@angular/material/snack-bar';

@Component({
    selector:    'app-dashboard',
    templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit
{
    public forgotForm: FormGroup;
    public error: any;
    public submitted = false;

    public constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private authService: AuthService,
        private snackBar: MatSnackBar,
    )
    {
    }

    public get f()
    {
        return this.forgotForm.controls;
    }

    public ngOnInit(): void
    {
        this.forgotForm = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
            url:   [`${environment.webUrl}/reset-password`],
        });
    }

    public onSubmit(): void
    {
        this.submitted = true;

        if (this.forgotForm.invalid) {
            return;
        }

        this.forgotForm.disable();

        this.authService.forgotPassword(this.forgotForm.value).subscribe(
            () => this.snackBar.open('Please check your inbox for an email.'),
            (error: HttpErrorResponse) => {
                this.forgotForm.enable();
                this.error = error;
            },
        );
    }
}
