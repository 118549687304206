import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SettingsService
{
    private SETTINGS_KEY: string = 'settings';

    public get authUser()
    {
        return this.getValue('auth_user');
    }

    public setValue(key: string, value: any)
    {
        return localStorage.setItem(`${this.SETTINGS_KEY}_${key}`, JSON.stringify(value));
    }

    public getValue(key: string)
    {
        return JSON.parse(localStorage.getItem(`${this.SETTINGS_KEY}_${key}`));
    }

    public insertValue(key: string, item: any)
    {
        let results = this.getValue(key);

        results.push(item);
        this.setValue(key, results);
    }

    public editValue(key: string, item: any)
    {
        let results = this.getValue(key);
        let index = results.findIndex(r => r.id === item.id);

        results[index] = item;

        this.setValue(key, results);
    }

    public deleteValue(key: string, item: any)
    {
        let results = this.getValue(key);
        this.setValue(key, results.filter(r => r.id !== item.id));
    }
}
