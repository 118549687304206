import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import { ActivatedRoute, Router } from "@angular/router";

import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-dashboard",
    templateUrl: "./reset-password.component.html"
})
export class ResetPasswordComponent implements OnInit {
    public token: string;
    public resetForm: FormGroup;
    public error: any;
    public submitted: boolean = false;

    public constructor(private snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private authService: AuthService) {
        this.token = this.route.snapshot.paramMap.get("token");
    }

    public get form() {
        return this.resetForm.controls;
    }

    public ngOnInit(): void {
        this.resetForm = this.fb.group({
            email: [null, Validators.required],
            password: [null, Validators.required],
            password_confirmation: [null, Validators.required]
        });
    }

    public onSubmit(): void {
        this.submitted = true;

        if (this.resetForm.invalid) {
            return;
        }

        this.resetForm.disable();

        this.authService.resetPassword(this.token, this.resetForm.value).subscribe(
            response => {
                this.snackBar.open("Please login again.", "Ok.", { duration: 2000 });
                this.router.navigateByUrl("login").then(r => r);
            },
            (error: HttpErrorResponse) => {
                this.resetForm.enable();
                this.error = error;
            }
        );
    }
}
