import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseService } from './base.service';
import { Params } from '@angular/router';
import { SettingsService } from '../../core/auth/services/settings.service';

@Injectable({
    providedIn: 'root',
})
export abstract class DataService extends BaseService {
    protected constructor(
        protected httpClient: HttpClient,
        private settingsServices: SettingsService
    ) {
        super();
    }

    public list(path: string, params: Params = null): Observable<any> {
        return this.httpClient
            .get(`${this.apiUrl}/${path}`, { params: params })
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public listWithCache(path: string, params: Params = null): Observable<any> {
        const cache = this.settingsServices.getValue(path);
        if (cache) {
            return of(cache);
        }
        return this.httpClient
            .get(`${this.apiUrl}/${path}`, { params: params })
            .pipe(switchMap((response: any) => {
                this.settingsServices.setValue(path, response);
                return of(response);
            }));
    }


    public show(path: string, id: string | number): Observable<any> {
        return this.httpClient
            .get<any>(`${this.apiUrl}/${path}/${id}`)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public create(path: string, data: any) {
        return this.httpClient
            .post<any>(`${this.apiUrl}/${path}`, data)
            .pipe(switchMap((response: any) => of(response)));
    }

    public update(path: string, data: any): Observable<any> {
        return this.httpClient
            .put<any>(`${this.apiUrl}/${path}`, data)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public delete(path: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/${path}`);
    }

    public deleteWithParam(path: string, params: Params = null): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/${path}`, { params: params });
    }

    public pageSize(){
        return 25
    }
    public dashboardPageSize(){
        return 5
    }
}
