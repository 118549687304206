import {NgModule}                                   from '@angular/core';
import {CommonModule, DatePipe}                     from '@angular/common';
import {FormsModule, ReactiveFormsModule}           from '@angular/forms';
import {RouterModule}                               from '@angular/router';
import {MatMenuModule}                              from '@angular/material/menu';
import {MatButtonModule}                            from '@angular/material/button';
import {MatCardModule}                              from '@angular/material/card';
import {MatIconModule}                              from '@angular/material/icon';
import {MatFormFieldModule}                         from '@angular/material/form-field';
import {MatInputModule}                             from '@angular/material/input';
import {MatChipsModule}                             from '@angular/material/chips';
import {MatTabsModule}                              from '@angular/material/tabs';
import {MatDialogModule}                            from '@angular/material/dialog';
import {MatSelectModule}                            from '@angular/material/select';
import {MatTableModule}                             from '@angular/material/table';
import {MatCheckboxModule}                          from '@angular/material/checkbox';
import {MatDatepickerModule}                        from '@angular/material/datepicker';
import {MatExpansionModule}                         from '@angular/material/expansion';
import {MatRadioModule}                             from '@angular/material/radio';
import {FileSizePipe}                               from '../shared/pipes/file-size.pipe';
import {DefaultDatePipe}                            from '../shared/pipes/default-date.pipe';
import {ShortDatePipe}                              from '../shared/pipes/short-date.pipe';
import {LimitPipe}                                  from '../shared/pipes/limit.pipe';
import {SafePipe}                                   from '../shared/pipes/safe.pipe';
import {ParseHtmlPipe}                              from '../shared/pipes/parse-html.pipe';
import {HasRoleDirective}                           from '../shared/directives/has-role.directive';
import {CanDirective}                               from '../shared/directives/can.directive';
import {CantDirective}                              from '../shared/directives/cant.directive';
import {SweetAlert2Module}                          from '@sweetalert2/ngx-sweetalert2';
import {FaConfig, FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgxFileDropModule}                          from 'ngx-file-drop';
import {NgxPaginationModule}                        from 'ngx-pagination';
import {Ng2SearchPipeModule}                        from 'ng2-search-filter';
import {StatusCellRendererComponent}                from '../shared/components/grid/status/status-cell-renderer.component';
import {AgGridModule}                               from 'ag-grid-angular';
import {CdkTableModule}                             from '@angular/cdk/table';
import {TabsModule}                                 from 'ngx-bootstrap/tabs';
import {CollapseModule}                             from 'ngx-bootstrap/collapse';
import {QuillModule}                                from 'ngx-quill';
import {MatToolbarModule}                           from '@angular/material/toolbar';
import {MatSnackBarModule}                          from '@angular/material/snack-bar';
import {MatListModule}                              from '@angular/material/list';
import {MatSidenavModule}                           from '@angular/material/sidenav';
import {MatTooltipModule}                           from '@angular/material/tooltip';
import {MatSlideToggleModule}                       from '@angular/material/slide-toggle';
import {MatSliderModule}                            from '@angular/material/slider';
import {MatGridListModule}                          from '@angular/material/grid-list';
import {fas}                                        from '@fortawesome/pro-solid-svg-icons';
import {far}                                        from '@fortawesome/pro-regular-svg-icons';
import {ReturnProductShowComponent}                 from '../modules/orders/modal/return-product-show/return-product-show.component';
import {MultiValueRendererComponent}                from '../shared/components/grid/multi-value/multi-value-renderer.component';
import {ParseJsonPipe}                              from '../shared/pipes/parse-json.pipe';
import {ExternalUrlPipe}                            from '../shared/pipes/external-url.pipe';

@NgModule({
    imports: [
        CommonModule,
        SweetAlert2Module.forRoot({
            provideSwal: () => import('sweetalert2/dist/sweetalert2.js')
            .then(({default: swal}) => swal.mixin({
                confirmButtonText: 'Ok',
                showCancelButton:  true,
                cancelButtonText:  'Cancel',
                focusCancel:       true,
                reverseButtons:    true,
            })),
        }),
        QuillModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatMenuModule,
        MatButtonModule,
        MatCardModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatTabsModule,
        MatDialogModule,
        MatSelectModule,
        MatTableModule,
        MatRadioModule,
        MatCheckboxModule,
    ],
    declarations: [
        DefaultDatePipe,
        ShortDatePipe,
        LimitPipe,
        ParseJsonPipe,
        ParseHtmlPipe,
        SafePipe,
        ExternalUrlPipe,
        FileSizePipe,
        HasRoleDirective,
        CanDirective,
        CantDirective,
        StatusCellRendererComponent,
        MultiValueRendererComponent,
        ReturnProductShowComponent,
    ],
    exports:      [
        SweetAlert2Module,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        NgxFileDropModule,
        NgxPaginationModule,
        Ng2SearchPipeModule,
        RouterModule,
        AgGridModule,
        CollapseModule,
        NgxPaginationModule,
        TabsModule,
        CdkTableModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatMenuModule,
        MatButtonModule,
        MatCardModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatTabsModule,
        MatDialogModule,
        MatSelectModule,
        MatTableModule,
        MatRadioModule,
        MatCheckboxModule,
        MatGridListModule,
        DefaultDatePipe,
        ShortDatePipe,
        QuillModule,
        LimitPipe,
        ParseJsonPipe,
        ParseHtmlPipe,
        SafePipe,
        ExternalUrlPipe,
        FileSizePipe,
        HasRoleDirective,
        CanDirective,
        CantDirective,
    ],
    providers:    [
        DatePipe,
        ShortDatePipe,
    ],
})
export class CoreModule
{
    public constructor(
        faConfig: FaConfig,
        library: FaIconLibrary,
    )
    {
        faConfig.defaultPrefix = 'fas';

        library.addIconPacks(fas, far);
    }
}
