import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
    name: 'shortDate',
})
export class ShortDatePipe extends DatePipe implements PipeTransform
{
    public transform(value: null | undefined): null;
    public transform(value: Date | string | number | null | undefined): string | null;
    public transform(value: Date | string | number | null | undefined): string | null
    {
        return super.transform(value, 'dd/MM/y');
    }
}
