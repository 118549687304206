export const navItems: any[] = [
    {
        name: 'Dashboard',
        url:  '/app',
        icon: 'speed',
    },
    {
        name: "Team Call Dashboard", 
        url: "/app/teams/calls-dashboard", 
        icon: "speed"
    }, 
    {
        name: 'Opportunities',
        url:  '/app/opportunities',
        icon: 'business_center',
    },
    {
        name: 'Quotes',
        url:  '/app/quotes',
        icon: 'loop',
    },
    {
        name: 'Organisations',
        url:  '/app/organisations',
        icon: 'apartment',
    },
    {
        name: 'Targets',
        url:  '/app/targets',
        icon: 'apartment',
    },
    {
        name: 'Contacts',
        url:  '/app/contacts',
        icon: 'contacts',
    },
     // Pankaj,27-july-23,Ticket-3630,add team links
     {
        name: 'Teams',
        url:  '/app/teams',
        icon: 'people',
    },
    {
        name: 'Calendar',
        url:  '/app/calendar',
        icon: 'calendar_today',
    },
    {
        name: 'Purchase Orders',
        url:  '/app/purchase-orders',
        icon: 'add_shopping_cart',
    },
    {
        name: 'Sale Orders',
        url:  '/app/sale-orders',
        icon: 'shopping_basket',
    },
    // {
    //     name: 'Sites',
    //     url:  '/app/sites',
    //     icon: 'location_on',
    // },
    {
        name: 'Projects',
        url:  '/app/projects',
        icon: 'location_on',
    },
    {
        name: 'Pipelines',
        url:  '/app/pipelines',
        icon: 'circle',
    },
];

export const productNavItems: any[] = [
    {
        name: 'Product',
        url:  '/app/products',
        icon: 'shopping_basket',
    },
    {
        name: 'Product Group',
        url:  '/app/products/opt/groups',
        icon: 'business_center',
    },
    {
        name: 'Product Category',
        url:  '/app/products/opt/categories',
        icon: 'business_center',
    },
];
export const activityNavItems: any[] = [
    {
        name: 'Task',
        url:  '/app/tasks',
        icon: 'check_circle',
    },
    {
        name: 'Calls',
        url:  '/app/calls',
        icon: 'call',
    },
];
export const settingNavItems: any[] = [
    {
        name: 'Users',
        url:  '/app/users',
        icon: 'groups',
    },
    {
        name: 'Roles',
        url:  '/app/roles',
        icon: 'policy',
    },
];
