import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

import { MatTooltipModule } from "@angular/material/tooltip";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { AgGridModule } from "ag-grid-angular";
import { JwtModule } from "@auth0/angular-jwt";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { QuillModule } from "ngx-quill";
import { CoreModule } from "./core/core.module";
import { AuthModule } from "./core/auth/auth.module";
import { LayoutComponent } from "./core/dashboard/components/containers";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ErrorInterceptor } from "./shared/interceptors/error.interceptor";
import { CommentsComponent } from "./shared/components/comments/comments.component";
import { ActionsCellRendererComponent } from "./shared/components/grid/actions/actions-cell-renderer.component";
import { LinkCellRendererComponent } from "./shared/components/grid/link/link-cell-renderer.component";
import { LinkRendererComponent } from "./shared/components/grid/link-renderer/link-renderer.component";
import { ShortDatePipe } from "./shared/pipes/short-date.pipe";
import { BinaryIconCellRendererComponent } from "./shared/components/grid/binary-icon/binary-icon-cell-renderer.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { NoAuthGuard } from "./shared/guards/no-auth.guard";
import { ShowOrderComponent } from "./modules/orders/show/show-order/show-order.component";
import { ValueRendererComponent } from './shared/components/grid/value-renderer/value-renderer.component';
import { ConfirmationDialogComponent } from './shared/components/generic-dialog/confirmation-dialog.component';
import { SbMatControl } from './shared/base-classes/sb-mat-control.class';
import { ActivityLinkRendererComponent } from './shared/components/grid/activity-link-renderer/activity-link-renderer.component';



@NgModule({
    imports: [
        BrowserModule,
        Ng2SearchPipeModule,
        CoreModule,
        AuthModule,
        AppRoutingModule,
        ReactiveFormsModule,
        AgGridModule,
        HttpClientModule,
        BrowserAnimationsModule,
        PerfectScrollbarModule,
        FormsModule,
        QuillModule.forRoot({
            modules: {
                syntax: false,
                toolbar: [[{ header: [false, 1, 2, 3, 4, 5, 6] }], ["bold", "italic", "strike", "code"], [{ list: "bullet" }, { list: "ordered" }], ["link", "image"], [{ color: [] }, { background: [] }], ["code-block", "blockquote"], ["clean"]]
            }
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: () => {
                    return localStorage.getItem("accessToken");
                },
                allowedDomains: ["localhost:4200","localhost:4300","localhost:4500", 'blend-api.localhost',"blend-api.test", "staging.api.blend.theonecrm.co.uk", "staging.api1.blend.theonecrm.co.uk", "staging.api.think360.theonecrm.co.uk"],
                disallowedRoutes: ["//blend-api.test/api/v1/login", "//blend-api.test/api/v1/join/", "//staging.api.blend.theonecrm.co.uk/api/v1/login", "//staging.api.blend.theonecrm.co.uk/api/v1/join/", "//staging.api1.blend.theonecrm.co.uk/api/v1/login", "//staging.api1.blend.theonecrm.co.uk/api/v1/join/", "//staging.api1.think360.theonecrm.co.uk/api/v1/login", "//staging.api1.think360.theonecrm.co.uk/api/v1/join/"]
            }
        }),
        MatDialogModule,
        MatIconModule,
        MatSidenavModule,
        MatListModule,
        MatButtonModule,
        MatMenuModule,
        MatInputModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatExpansionModule,
        MatAutocompleteModule
    ],
    declarations: [AppComponent, LayoutComponent, CommentsComponent, LinkCellRendererComponent,ActivityLinkRendererComponent,LinkRendererComponent, ActionsCellRendererComponent, BinaryIconCellRendererComponent, ShowOrderComponent,ValueRendererComponent,ConfirmationDialogComponent,SbMatControl],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] }, { provide: MAT_DATE_LOCALE, useValue: "en-GB" }, { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }, { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, Title, ShortDatePipe, AuthGuard, NoAuthGuard],


    bootstrap: [AppComponent],
})
export class AppModule { }
